export class HtmlHelper
{
    /**
     * Select a sibling for a string of valid CSS selector.
     * 
     * @param element 
     * @param selectors A valid CSS selector string.
     */
    selectElementSibling(element: HTMLElement, selectors: string): Element | null
    {
        let parentElement = element.parentElement;

        if (parentElement != null) 
        {
            return parentElement.querySelector(selectors);
        }
        else
        {
            return null;
        }
    }
}

export default HtmlHelper;