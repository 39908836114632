export class TabHelper
{
    setup()
    {
        this.fixScroll();
    }

    /**
     * Fix scroll position when clicking on tabs.
     */
    fixScroll()
    {
        let tabMenus = document.querySelectorAll('.w-tab-menu');

        tabMenus.forEach((tab: Element) => 
        {
            tab.addEventListener('click', () =>
            {
                let targetElement = (tab as HTMLElement).nextElementSibling;
                while (targetElement)
                {
                    if (targetElement.classList.contains('w-tab-content'))
                    {
                        break;
                    }
                    targetElement = targetElement.nextElementSibling;
                }

                if (targetElement)
                {
                    let rect = targetElement.getBoundingClientRect(); // Get bounding rect for target element
                    let absoluteTop = rect.top + window.scrollY; // Calculate absolute top

                    // Calculate offset based on screen size
                    let offset;
                    if (window.innerWidth <= 480)
                    { // Mobile devices
                        offset = 160;
                    } 
                    else if (window.innerWidth <= 1024)
                    { // Tablets and smaller laptops
                        offset = 60;
                    } 
                    else
                    { // Desktops and larger laptops
                        offset = 100;
                    }

                    let offsetPosition = absoluteTop - offset; // Apply the offset

                    // Scroll to the target element
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            });
        });
    }
}

export default TabHelper;