import React from 'react';
import ReactDom from 'react-dom/client';
import SlickSliderComponent, { GalleryImage } from '../components/SlickSliderComponent';

export type ScrSetImage =
{
    /** Eg: 800w */
    width: number,
    /** The URL of the image for the specified width. */
    url: string
}

export class SlickSliderHelper
{
    /**
     * Activate the slick-slider components on the Webflow page. 
     * A SlickSliderComponent gets its values from HTML elements marked with 'slick-slider-part' attribute. NOTE: [slick-slider-part="item"] is not in use
     * The aspect ratio of each image can be set via the aspect-ratio attribute.
     */
    activateSlickSlidersOnPage()
    {
        //find the slick slider markup containers
        let containers = this.getSlickSiderMarkupContainers();
        for (let container of containers)
        {
            //find the images
            let images = this.getImagesFromMarkupContainer(container);

            //create the SlickSliderComponent in the containers parent element
            let scrollSpyRoot = ReactDom.createRoot(container.parentElement as HTMLElement);
            scrollSpyRoot.render(
                <SlickSliderComponent images={this.convertImageElementsToGalleryImages(images)} />
            )
        }
    }

    /**
     * Get all HTML elements marked with [slick-slider-part="container"]
     */
    getSlickSiderMarkupContainers(): HTMLElement[]
    {
        let containers: HTMLElement[] = [];

        document.querySelectorAll('[slick-slider-part="container"]').forEach(container => {
            containers.push(container as HTMLElement);
        });

        return containers;
    }

    /**
     * Get the image URLs from a slick slider markup container.
     * 
     * @param markupContainer 
     */
    getImagesFromMarkupContainer(markupContainer: HTMLElement): HTMLImageElement[]
    {
        let images: HTMLImageElement[] = [];

        markupContainer.querySelectorAll('[slick-slider-part="image"]').forEach(imageElement => {
            images.push(imageElement as HTMLImageElement);
        });

        return images;
    }

    /**
     * Convert the HTML Image elements to GalleryImage objects.
     */
    convertImageElementsToGalleryImages(images: HTMLImageElement[])
    {
        let galleryImages: GalleryImage[] = [];

        for (let i = 0; i < images.length; i++)
        {
            let image = images[i];
            let srcSet = this.getSrcSetFromImage(image);
            let thumbnailImageUrl = srcSet.length > 0 ? srcSet[0].url : image.src;
            
            let galleryImage: GalleryImage = {
                index: i,
                url: image.src,
                thumbnailUrl: thumbnailImageUrl,
                aspectRatio: this.getImageAspectRatio(image)
            };
            galleryImages.push(galleryImage);
        }

        return galleryImages;
    }

    /**
     * NOT IN USE.
     * Get the HTMLElement items from the markup container. 
     * 
     * @param markupContainer 
     */
    getItemsFromMarkupContainer(markupContainer: HTMLElement): HTMLElement[]
    {
        let items: HTMLElement[] = [];

        markupContainer.querySelectorAll('[slick-slider-part="item"]').forEach(item => {
            items.push(item as HTMLElement);
        });

        return items;
    }

    /**
     * Get the srcset from an image.
     * 
     * @param image 
     */
    getSrcSetFromImage(image: HTMLImageElement)
    {
        let srcSet: ScrSetImage[] = [];

        let sources = image.srcset.split(',');
        for (let source of sources)
        {
            let splittedSource = source.trim().split(' ');
            if (splittedSource.length == 2 && splittedSource[1].endsWith('w'))
            {
                let srcSetImage: ScrSetImage = {
                    width: parseInt(splittedSource[1].replace('w', '')),
                    url: splittedSource[0]
                };
                srcSet.push(srcSetImage);
            }
        }

        srcSet.sort((a, b) => a.width - b.width);

        return srcSet;
    }

    /**
     * Read the aspect ratio (width/height) info from the attributes. 
     * 
     * @param image 
     * @returns The aspect ratio or the default aspect ratio if nothing found.
     */
    getImageAspectRatio(image: HTMLImageElement): number
    {
        if (image.hasAttribute('aspect-ratio'))
        {
            return parseFloat(image.getAttribute('aspect-ratio') as string);
        }
        else
        {
            return 1.5;
        }
    }
}

export default SlickSliderHelper;