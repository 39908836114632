import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { GalleryImage } from './SlickSliderComponent';

type SlidingImagePosition = {
    /** Image has been scrolled out of the container, hidden at the left side. */
    isOutOfContainerToTheLeft: boolean,
    /** Image has been scrolled out of the container, hidden at the right side. */
    isOutOfContainerToTheRight: boolean,
    /** If the image is out of the container, this is the measure of how far out it is.
     *  Negative number = out to the left.
     * Positive number = out to the right.
    */
    distanceFromContainer: number
}

/**
 * Get the Sliding Image's position relative to it's container.
 * 
 * @param slidingImageElement The image HTML element.
 * @param containerElement The parent container HTML element which holds the images.
 */
export function getPosition(slidingImageElement: HTMLDivElement, containerElement: HTMLDivElement): SlidingImagePosition 
{
    let position: SlidingImagePosition = {
        isOutOfContainerToTheLeft: false,
        isOutOfContainerToTheRight: false,
        distanceFromContainer: 0
    }

    let boundingClientRect = slidingImageElement.getBoundingClientRect();
    let containerBoundingClientRect = containerElement.getBoundingClientRect();
    if (boundingClientRect !== undefined && containerBoundingClientRect !== undefined)
    {
        let x_relativeToContainer = boundingClientRect.x - containerBoundingClientRect.x;
        //the left side of the image is hanging out to the left
        position.isOutOfContainerToTheLeft = x_relativeToContainer < 0; 
        //the right side of the image is hanging out to the right
        position.isOutOfContainerToTheRight = x_relativeToContainer + boundingClientRect.width > containerElement.clientWidth;
        if (position.isOutOfContainerToTheLeft || position.isOutOfContainerToTheRight)
        {
            if (position.isOutOfContainerToTheLeft)
            {
                position.distanceFromContainer = boundingClientRect.x;
            }
            else
            {
                position.distanceFromContainer = (boundingClientRect.x + boundingClientRect.width) - window.screen.width;
            }
        }
    }

    return position;
};

export interface SlidingImageComponentProps
{
    image: GalleryImage;
    className?: string;
    isSelected?: boolean;
    containerElement: HTMLDivElement;
    onClick: () => void;
    /** Request for scrolling back the thumbnail into the screen. 
     * Negative distance: is out to the left.
     * Positive distance: is out to the right.
    */
    scrollMeBackIntoTheContainer?: (distanceFromContainer: number) => void;
}

/**
 * Sliding image component. The ref is forwarded:
 * https://reactjs.org/docs/forwarding-refs.html
 * https://www.carlrippon.com/react-forwardref-typescript/
 */
const SlidingImageComponent = React.forwardRef<HTMLDivElement, SlidingImageComponentProps>((props, ref) =>
{
    const [height, setHeight] = useState<number>(0);
    const innerRef = useRef<HTMLDivElement>(null);

    // https://www.carlrippon.com/using-a-forwarded-ref-internally/
    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
        ref,
        () => innerRef.current
    );

    /** Calculate height based on the aspect ratio. Aspect ratio = width/height */
    useEffect(() => {
        let widthInPixels = innerRef.current?.clientWidth as number;
        setHeight(widthInPixels / props.image.aspectRatio);
    });

    /** Check if the selected image is out of the container or not. Scroll back if it's out. */
    useEffect(() => {
        if (props.isSelected && props.containerElement != null)
        {
            let position = getPosition(innerRef.current as HTMLDivElement, props.containerElement);

            if (position.isOutOfContainerToTheLeft || position.isOutOfContainerToTheRight)
            {
                if (props.scrollMeBackIntoTheContainer !== undefined) props.scrollMeBackIntoTheContainer(position.distanceFromContainer);
            }
        }
    }, [props.isSelected]);
    
    /** Overlay markup */
    let overlaySelectionMarkerClass = props.isSelected ? 'sliding-image-overlay-selected' : 'sliding-image-overlay-not-selected';

    /** Render. */
    return (
        <div
            ref={innerRef}
            className={'sliding-image ' + props.className}
            onClick={() => props.onClick()}
            style={{height: height + 'px'}}
        >
            <img src={props.image.thumbnailUrl} />
            <div className={'sliding-image-overlay ' + overlaySelectionMarkerClass}></div>
        </div>
    );
});

export default SlidingImageComponent;