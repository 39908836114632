export class ClickAndScrollHelper
{
    /**
    /* Scroll to a DOM element.
    */
    scrollToElement(idElement: string, behavior: ScrollBehavior = 'smooth', changeUrl = false)
    {
        //find element
        let element = document.getElementById(idElement);

        if (element != null)
        {
            element.scrollIntoView({ behavior: behavior });

            if (changeUrl)
            {
                let urlSplits = document.location.toString().split('#');
                if (urlSplits.length > 0)
                {
                    let baseUrl = urlSplits[0];
                    document.location = baseUrl + '#' + idElement;
                }
            }
        }
    }

    /**
     * Enable anchor navigation for a button on a Webflow page. The user clicks a button and the page scrolls to the specified anchor name (section ID).
     * For this to work the following setup is needed on the page:
     * - A HTML embed component (called the locator entry) with the custom attribute [button-locator="entry"].
     * - The embed component contains the following div <div target = "ANCHOR_NAME"></div>. ANCHOR_NAME is dynamic.
     * - The button (locator target) has the following custom attribute: [button-locator="target"].
     * - The locator entry and the locator target must be nested inside the same paren element (not necessarily siblings).
     * - The section to which the button navigates has the id ANCHOR_NAME.
     * - The global array variable anchorNames needs to be set and have the section IDs as values (anchor names). Tip: add the IDs to the array via array.push() in a Webflow Collection Item.
     */
    setupButtonAnchorNavigationOnPage()
    {
        if (typeof (anchorNames) == 'undefined') return;

        for (let anchorName of anchorNames)
        {
            let locatorParents = this.getButtonLocatorParents(anchorName); // get all parents for the anchor name (this is useful when there are multiple buttons that navigate to the same section)
            for (let locatorParent of locatorParents)
            {
                //find the link button
                let button = this.getButtonLocatorTarget(locatorParent);
                if (button != null)
                {
                    //add the click event to the button which will scroll to the specified section
                    (button as HTMLElement).onclick = () =>
                    {
                        this.scrollToElement(anchorName);
                    };
                }
            }
        }
    }

    /**
     * Find the parent element for [button-locator="entry"] -->> <div target = "ANCHOR_NAME">
     * 
     * @param anchorName 
     */
    getButtonLocatorParent(anchorName: string): HTMLElement | null
    {
        let parent = null;
        
        let locator = document.querySelector(`[button-locator="entry"] div[target="${anchorName}"]`);

        if (locator != null)
        {
            //find the parent collection item
            parent = locator.parentElement?.parentElement as HTMLElement;
        }

        return parent;
    }


    /**
     * Find the parents for all [button-locator="entry"] elements.
     * This is useful when there are multiple buttons that navigate to the same section.
     * 
     * @param anchorName
     */
    getButtonLocatorParents(anchorName: string): HTMLElement[]
    {
        let parents: HTMLElement[] = [];

        let locators = document.querySelectorAll('[button-locator="entry"] div[target="' + anchorName + '"]');

        locators.forEach((locator) =>
        {
            let parent = locator.parentElement?.parentElement as HTMLElement;
            if (parent != null)
            {
                parents.push(parent);
            }
        });

        return parents;
    }

    /**
     * Find the button-locator target (the button).
     * 
     * @param parent 
     */
    getButtonLocatorTarget(parent: HTMLElement)
    {
        return parent.querySelector('[button-locator="target"]');
    }
}

export default ClickAndScrollHelper;