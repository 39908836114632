import InHouseStatusGenerated, { InHouseStatusGeneratedEntityManager } from './generated/InHouseStatus.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class InHouseStatusEntityManager extends InHouseStatusGeneratedEntityManager<InHouseStatus>
{
    constructor()
    {
        super({
            createEntity: () => new InHouseStatus(),
        });
    }
}

export class InHouseStatus extends InHouseStatusGenerated
{
    static _manager: InHouseStatusEntityManager;
    static get manager(): InHouseStatusEntityManager
    {
        if (!this._manager) this._manager = new InHouseStatusEntityManager();
        return this._manager;
    }
}

export default InHouseStatus;