import ScrollSpyHelper from './ScrollSpyHelper';

export class MenuHelper
{
    static stickyMenuButtonThreshold: number = 70;
    
    /**
     * Activate/deactivate sticky menu based on scroll position.
     */
    watchSticky()
    {
        addEventListener('scroll', () => {
            //activate if document has been scrolled down from the top
            this.activateNavbarMenuButtonSticky(document.documentElement.scrollTop > MenuHelper.stickyMenuButtonThreshold);
        });

        addEventListener('load', () => {
            //activate if document has been scrolled down from the top
            this.activateNavbarMenuButtonSticky(document.documentElement.scrollTop > MenuHelper.stickyMenuButtonThreshold);
        });
    }
    
    /**
     * Activate the sticky menu button for Webflow.
     */
    activateNavbarMenuButtonSticky(activate = true)
    {
        //get navbar and the menu button
        let navbar = this.getNavbar();
        let menuButton = this.getNavbarMenuButton();
        
        if (navbar != null && menuButton != null)
        {
            let logo = (navbar.querySelector('[navbar-logo="true"]') as HTMLElement);
            let contact = (navbar.querySelector('[menu-contact-us="true"]') as HTMLElement);

            //navbar sticky
            activate ? navbar.classList.add('navbar-sticky') : navbar.classList.remove('navbar-sticky');

            //adjust if ScrollSpy is active
            let scrollSpyHelper = new ScrollSpyHelper();
            if (activate)
            {
                //show/hide the rest of the navbar elements
                scrollSpyHelper.isScrollSpyActive() ? menuButton.classList.add('menu-button-scrollspy-sticky') : menuButton.classList.remove('menu-button-scrollspy-sticky');
                scrollSpyHelper.isScrollSpyActive() ? logo.classList.add('navbar-sticky-hide-element') : logo.classList.remove('navbar-sticky-hide-element');
                scrollSpyHelper.isScrollSpyActive() ? contact.classList.add('navbar-sticky-hide-element') : contact.classList.remove('navbar-sticky-hide-element');
            }
            else
            {
                menuButton.classList.remove('menu-button-scrollspy-sticky');
                logo.classList.remove('navbar-sticky-hide-element');
                contact.classList.remove('navbar-sticky-hide-element');
            }
        }
    }
    
    /**
     * Get the Navbar.
     */
    getNavbar(): HTMLElement | null
    {
        return document.querySelector('[navbar="true"]');
    }

    /**
     * Get the menu button.
     */
    getNavbarMenuButton(): HTMLElement | null
    {
        return document.querySelector('[navbar-menu-button="true"]');
    }

    /**
     * Fix the following issues:
     * - Body should not be scrollable when the menu is open.
     * - Menu should be scrollabe when it is open.
     */
    fixMobileMenu()
    {
        let navbarMenuButton = this.getNavbarMenuButton();
        let navBarLogo = document.querySelector('[navbar-logo="true"]');
        let html = document.querySelector('html');
        let body = document.querySelector('body');

        if (navbarMenuButton != null && navBarLogo != null && html != null && body != null)
        {
            navbarMenuButton.addEventListener('click', () => {
                // track if the menu is open
                let isOpen = navbarMenuButton!.classList.contains('w--open');

                if (isOpen)
                {// close the menu
                    // enable body scroll
                    html!.classList.remove('not-scrollable');
                    body!.classList.remove('not-scrollable');

                    // jump to the last scroll position
                    let lastScrollPosition = body!.getAttribute('data-scroll');
                    if (lastScrollPosition != null)
                    {
                        // jump
                        window.scrollTo(0, parseInt(lastScrollPosition));

                        // remove the last scroll position from the body tag
                        body!.removeAttribute('data-scroll');
                        
                        // wait 100 ms than scroll down a pixel to fix menu bar
                        setTimeout(() => {
                            window.scrollTo(0, parseInt(lastScrollPosition!) + 1);
                        }, 100);
                    }
                }
                else
                {// open the menu
                    // save the last scroll position to the body tag
                    body!.setAttribute('data-scroll', window.pageYOffset.toString());
                    
                    // jump to top of the page
                    window.scrollTo(0, 0);
                    
                    // disable body scroll
                    html!.classList.add('not-scrollable');
                    body!.classList.add('not-scrollable');

                    // CSS fixes
                    let navOverlay = document.querySelector('.w-nav-overlay');
                    if (navOverlay != null)
                    {
                        navOverlay!.classList.add('pb-env');
                    }
                }
            });
        }
    }
}

export default MenuHelper;