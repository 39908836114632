export class ScrollSpyHelper
{
    /**
     * Find the page elements to spy on.
     */
    getElementsToTrack(): HTMLElement[]
    {
        let elements: HTMLElement[] = [];
        
        let elementsFound = document.querySelectorAll('[needs-spying="true"]');
        elementsFound.forEach((element) => {
            if (window.getComputedStyle(element, null).display != 'none')
            {
                elements.push(element as HTMLElement);
            }
        });

        return elements;
    }

    /**
     * Get the the title attribute for the tracked element.
     * This will be displayed on the tracker element.
     */
    getTrackedElementInfo(trackedElement: HTMLElement): TrackedElementInfo | null
    {
        let locator = trackedElement.querySelector('.scrollspy-tracked-element-locator');
        let titlePartElements = trackedElement.querySelectorAll('[scroll-spy-title-part]');
        
        if (locator != null)
        {// find by locator (eg: itinerary days)
            return {
                id: locator.id,
                title: locator.getAttribute('scroll-spy-title') as string
            } 
        }
        else if (titlePartElements.length > 0)
        {// find by title parts (eg: travel-types/romantic-escapes)
            // set an id for the trackedElement (if not set)
            if (trackedElement.id == '')
            {
                trackedElement.id = '_' + Math.round(Math.random()*1000000);
            }
            
            // sort title parts and build the title
            let titleParts: {order_no: number, title: string}[] = [];
            titlePartElements.forEach(titlePartElement => {
                titleParts.push({
                    order_no: parseInt(titlePartElement.getAttribute('scroll-spy-title-part') as string),
                    title: titlePartElement.innerHTML
                })
            });
            titleParts = titleParts.sort((a, b) => a.order_no >= b.order_no ? 1 : -1)
            let titleStrings = titleParts.map(tp => tp.title)
            let title = titleStrings.join(' ');

            return {
                id: trackedElement.id,
                title: title
            }
        }
        else
        {
            return null;
        }
    }

    /**
     * The distance between the top of the window and the top of the element.
     * https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
     * 
     * @param element 
     */
    getElementTop(element: HTMLElement): number
    {
        return element.getBoundingClientRect().y;
    }

    /**
     * The distance between the top of the window and the bottom of the element.
     * https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
     * 
     * @param element 
     */
    getElementBottom(element: HTMLElement): number
    {
        return element.getBoundingClientRect().y + element.getBoundingClientRect().height;
    }

    /**
     * Check if the top of the element is visible on the screen.
     * 
     * @param element 
     */
    isElementTopVisible(element: HTMLElement): boolean
    {
        if (element != null)
        {
            let elementTop = this.getElementTop(element);

            return elementTop > 0 && elementTop < window.innerHeight;
        }
        else
        {
            return false
        }
    }

    /**
     * Check if the ScrollSpy is active and visible.
     */
    isScrollSpyActive(): boolean
    {
        if (document.querySelector('.scrollspy-container') != null)
        {
            return document.querySelector('.scrollspy-container')?.classList.contains('visible') as boolean;
        }
        else
        {
            return false;
        }
    }
}

export interface TrackedElementInfo
{
    id: string;
    title: string;
}

export default ScrollSpyHelper;