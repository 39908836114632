import Tracker from '@xFrame4/common/Tracker';

export class AdminTracker extends Tracker
{
    protected static createInstance(): AdminTracker
    {
        return new AdminTracker();
    }

    async init()
    {
        this.handleScriptLoading = true;
        this.trackWithGoogle = true;
        this.trackWithFacebook = true;
        this.trackWithFacebookConversionsApi = true;
        this.trackWithGoogleMeasurementProtocol = true;

        await super.init();
    }
}

export default AdminTracker;