import HtmlHelper from './HtmlHelper';

export class ButtonHelper
{
    /**
     * Helps Webflow buttons to navigate to a certain link (eg: navigate to a dynamic anchor on a different page). 
     * For this to work the following setup is needed on the Webflow page:
     * - The buttons need to have the following attribute: [button-needs-help-with-url="true"]
     * - A dynamic sibling div needs to be added next to the button with the class="button-url-holder" and hold the target URL in the [button-url] attribute.
     */
    helpButtonsOnPageToNavigateToLink()
    {
        let buttons = document.querySelectorAll('[button-needs-help-with-url="true"]');
        buttons.forEach(button => {
            this.helpTheButtonToNavigateToALink(button as HTMLElement);
        });
    }
    
    /**
     * Navigate to an URL when the button is clicked.
     * 
     * @param buttonElement 
     */
    helpTheButtonToNavigateToALink(buttonElement: HTMLElement)
    {
        //get the link
        let url = this.getUrlForButtonInNeed(buttonElement);

        //setup navigation
        if (url != null)
        {
            buttonElement.onclick = () => {
                document.location.href = url as string;
            };
        }
    }

    /**
     * Get the buttons that need help with navigating to an URL.
     */
    getButtonsThatNeedHelpWithUrl()
    {
        return document.querySelectorAll('[button-needs-help-with-url="true"]');
    }

    /**
     * Get the URL for the button in need. The sibling element holds the URL.
     * 
     * @param buttonElement The button that needs help with the navigation.
     * @returns The URL to which the button will navigate.
     */
    getUrlForButtonInNeed(buttonElement: HTMLElement): string | null
    {
        let htmlHelper = new HtmlHelper();
        let siblingHoldingTheUrl = htmlHelper.selectElementSibling(buttonElement, '.button-url-holder');

        if (siblingHoldingTheUrl != null)
        {
            return siblingHoldingTheUrl.getAttribute('button-url');
        }
        else
        {
            return null;
        }
    }
}

export default ButtonHelper;