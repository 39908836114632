import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import User from '@xFrame4/business/users/User';


export class TravelAgentGeneratedEntityManager<B extends TravelAgentGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'TravelAgent',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'name', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'user', type: BusinessEntityFieldType.ForeignKey, inputName: 'userId', inputProperty: 'user.id', relatedManager: User.manager },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'travelAgents',
        });
    }

}
export class TravelAgentGenerated extends BusinessEntity
{
	name!: string;
	user: User | null = null;

}

export default TravelAgentGenerated;