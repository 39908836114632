import React, { useEffect, useRef, useState } from 'react';
import { FunctionComponent } from 'react';
import LightboxComponent from './LightboxComponent';
import SlidingImageContainerComponent from './SlidingImageContainerComponent';

enum NavigationDirection
{
    Left,
    Right
}

export interface GalleryImage 
{
    index: number;
    url: string;
    thumbnailUrl: string;
    /** Width/height */
    aspectRatio: number;
}

interface SlickSliderComponentProps
{
    images: GalleryImage[];
}

/**
 * Uses:
 * - https://react-slick.neostack.com/docs/get-started (Do not forget to include CSS on Webflow)
 * - 
 */
const SlickSliderComponent: FunctionComponent<SlickSliderComponentProps> = (props) => 
{
    const [showLightbox, setShowLightbox] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<GalleryImage>(props.images[0]);

    /**
     * On image click.
     */
    const onImageClick = (galleryImage: GalleryImage) =>
    {
        setSelectedImage(galleryImage);
        setShowLightbox(true);
    };

    /**
     * Close the Lightbox.
     */
    const closeLightbox = () =>
    {
        setShowLightbox(false);
    };

    /** Render. */
    return (
        <div>
            <LightboxComponent
                show={showLightbox}
                images={props.images}
                selectedImageIndex={selectedImage.index}
                onClose={() => closeLightbox()}
            />

            <SlidingImageContainerComponent
                images={props.images}
                enableArrows={true}
                imageClassName="slick-image"
                onImageClick={(galleryImage) => onImageClick(galleryImage)}
            />
        </div>
    );
}

export default SlickSliderComponent;