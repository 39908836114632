import TravelAgentGenerated, { TravelAgentGeneratedEntityManager } from './generated/TravelAgent.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class TravelAgentEntityManager extends TravelAgentGeneratedEntityManager<TravelAgent>
{
    constructor()
    {
        super({
            createEntity: () => new TravelAgent(),
        });
    }
}

export class TravelAgent extends TravelAgentGenerated
{
    static _manager: TravelAgentEntityManager;
    static get manager(): TravelAgentEntityManager
    {
        if (!this._manager) this._manager = new TravelAgentEntityManager();
        return this._manager;
    }
}

export default TravelAgent;