import ReactDom from 'react-dom/client';
import './public/css/style.css';
import ClickAndScrollHelper from './classes/ClickAndScrollHelper';
import ButtonHelper from './classes/ButtonHelper';
import ScrollSpyComponent from './components/ScrollSpyComponent';
import MenuHelper from './classes/MenuHelper';
import SlickSliderHelper from './classes/SlickSliderHelper';
import WebflowTracker from '@business/tracking/WebflowTracker';
import FormHelper from './classes/FormHelper';
import TabHelper from './classes/TabHelper';

// Track events with Google and Facebook (the dummy instance is used to activate the singleton pattern)
let dummyTracker = WebflowTracker.instance;

// button anchor scroll navigation inside the page
let scrollHelper = new ClickAndScrollHelper();
scrollHelper.setupButtonAnchorNavigationOnPage();

// help buttons to navigate to link
let buttonHelper = new ButtonHelper();
buttonHelper.helpButtonsOnPageToNavigateToLink();

// scrollspy for page
/**
let scrollSpyElement = document.getElementById('scrollspy');
if (scrollSpyElement != null)
{
    let scrollSpyRoot = ReactDom.createRoot(scrollSpyElement);
    scrollSpyRoot.render(
        <ScrollSpyComponent />
    )
}
 */

// sticky menu
let menuHelper = new MenuHelper();
menuHelper.watchSticky();

// mobile menu fixes
menuHelper.fixMobileMenu();

// slick slider
let slickSliderHelper = new SlickSliderHelper();
slickSliderHelper.activateSlickSlidersOnPage();

// contact forms and leads
let formHelper = new FormHelper();
formHelper.setup();

// tab scroll
let tabHelper = new TabHelper();
tabHelper.setup();