import React, { useEffect, useRef, useState } from 'react';
import { FunctionComponent } from 'react';
import { GalleryImage } from './SlickSliderComponent';
import SlidingImageComponent from './SlidingImageComponent';

enum NavigationDirection
{
    Left,
    Right
}

interface SlidingImageContainerComponentProps
{
    images: GalleryImage[];
    enableArrows: boolean
    selectedIndex?: number;
    className?: string;
    imageClassName?: string;
    onImageClick: (image: GalleryImage) => void;
}

const SlidingImageContainerComponent: FunctionComponent<SlidingImageContainerComponentProps> = (props) =>
{
    const [selectedImage, setSelectedImage] = useState<GalleryImage>(props.images[0]);
    const [imagesTotalWidth, setImagesTotalWidth] = useState<number>(0);
    const [trackWidth, setTrackWidth] = useState<number>(0);
    const ref = useRef<HTMLDivElement>(null);
    const refSliderTrackInner = useRef<HTMLDivElement>(null);
    const refImages: React.RefObject<HTMLDivElement>[] = props.images.map(image => useRef<HTMLDivElement>(null));

    // calculate the compound width of the images and the track width
    useEffect(() => {
        let totalWidth = 0;
        for (let refImage of refImages)
        {
            totalWidth += refImage.current!.clientWidth;
        }
        setImagesTotalWidth(totalWidth);

        setTrackWidth(ref.current?.clientWidth as number);
    });

    /**
     * Navigate the images horizontally.
     * 
     * @param direction 
     */
    const navigate = (direction: NavigationDirection) =>
    {
        if (refImages.length > 0)
        {
            let scrollStep = refImages[0].current?.clientWidth as number;

            if (direction == NavigationDirection.Left)
            {
                refSliderTrackInner.current!.scrollLeft -= 2 * scrollStep;
            }
            else
            {
                refSliderTrackInner.current!.scrollLeft += 2 * scrollStep;
            }
        }
    };

    /**
     * Scroll the selected image back into the screen.
     * 
     * @param distanceFromContainer Negative distance: is out of the container to the left. Positive distance: is out of the container to the right.
     */
    const scrollSelectedImageBackIntoTheScreen = (distanceFromContainer: number) =>
    {
        let threshold = distanceFromContainer < 0 ? -150 : 150;
        if (refSliderTrackInner.current != null) refSliderTrackInner.current.scrollLeft += (distanceFromContainer + threshold);
    };

    /** Images. */
    let cmpImages = props.images.map((galleryImage, i) =>
    {
        return (
            <SlidingImageComponent
                ref={refImages[i]}
                key={galleryImage.index}
                className={props.imageClassName}
                image={galleryImage}
                isSelected={props.selectedIndex == galleryImage.index}
                containerElement={refSliderTrackInner.current as HTMLDivElement}
                onClick={() => onImageClick(galleryImage)}
                scrollMeBackIntoTheContainer={(distanceFromContainer) => scrollSelectedImageBackIntoTheScreen(distanceFromContainer)}
            />
        )
    });

    /**
     * On image click.
     */
    const onImageClick = (galleryImage: GalleryImage) =>
    {
        setSelectedImage(galleryImage);
        props.onImageClick(galleryImage);
    };

    /** Arrow logic */
    let hasArrows = props.enableArrows && (trackWidth < imagesTotalWidth);

    /** Render. */
    return (
        <div ref={ref} className={'sliding-image-container ' + props.className}>
            {hasArrows &&
                <div className="sliding-image-container-navigate navigate-left" onClick={() => navigate(NavigationDirection.Left)}>
                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0yMCAwIDI0IDQwIiB3aWR0aD0iMjQiIGhlaWdodD0iNDAiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHBhdGggZD0ibTAgMGg1djIzaDIzdjVoLTI4eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDN2MjNoMjN2M2gtMjZ6IiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg==" />
                </div>
            }
            <div className={'sliding-image-container-track ' + (hasArrows ? 'sliding-image-container-track-with-arrows' : 'sliding-image-container-track-no-arrows')}>
                <div ref={refSliderTrackInner} className="sliding-image-container-track-inner">
                    {cmpImages}
                </div>
            </div>
            {hasArrows &&
                <div className="sliding-image-container-navigate navigate-right" onClick={() => navigate(NavigationDirection.Right)}>
                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMjQgNDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMC0waDI4djI4aC01di0yM2gtMjN6IiBvcGFjaXR5PSIuNCIvPjxwYXRoIGQ9Im0xIDFoMjZ2MjZoLTN2LTIzaC0yM3oiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+" />
                </div>
            }
        </div>
    );
}

export default SlidingImageContainerComponent;