import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import InHouseStatus from '@business/forms/InHouseStatus';
import TravelAgent from '@business/agency/TravelAgent';

export enum WebflowLeadStatus
{
    NEW = 'NEW',
    APPROVED_FOR_TRACKING = 'APPROVED_FOR_TRACKING',
    NOT_APPROVED_FOR_TRACKING = 'NOT_APPROVED_FOR_TRACKING',

}
export enum WebflowLeadEvyssaStatus
{
    BOOKED = 'BOOKED',
    LOST = 'LOST',
    PENDING = 'PENDING',
    NO_CONTACT = 'NO_CONTACT',

}
export enum WebflowLeadSourceType
{
    WEBFLOW = 'WEBFLOW',
    FB_LEAD_AD = 'FB_LEAD_AD',
    ZOHO_SALES_IQ = 'ZOHO_SALES_IQ',
    MANUAL = 'MANUAL',

}

export class WebflowLeadGeneratedEntityManager<B extends WebflowLeadGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'WebflowLead',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'dateAdded', type: BusinessEntityFieldType.DateTime, isInput: false },
				{ name: 'email', type: BusinessEntityFieldType.VarChar },
				{ name: 'status', type: BusinessEntityFieldType.VarChar },
				{ name: 'evyssaStatus', type: BusinessEntityFieldType.VarChar },
				{ name: 'inHouseStatus', type: BusinessEntityFieldType.ForeignKey, inputName: 'inHouseStatusId', inputProperty: 'inHouseStatus.id', relatedManager: InHouseStatus.manager },
				{ name: 'inHouseStatusChangedDate', type: BusinessEntityFieldType.DateTime, isInput: false },
				{ name: 'sourceType', type: BusinessEntityFieldType.VarChar },
				{ name: 'name', type: BusinessEntityFieldType.VarChar },
				{ name: 'phone', type: BusinessEntityFieldType.VarChar },
				{ name: 'fieldsAndValues', type: BusinessEntityFieldType.Json },
				{ name: 'sourceUrl', type: BusinessEntityFieldType.VarChar },
				{ name: 'sourceName', type: BusinessEntityFieldType.VarChar },
				{ name: 'formId', type: BusinessEntityFieldType.VarChar },
				{ name: 'submitEventName', type: BusinessEntityFieldType.VarChar },
				{ name: 'approveEventName', type: BusinessEntityFieldType.VarChar },
				{ name: 'gaClientId', type: BusinessEntityFieldType.VarChar },
				{ name: 'fbp', type: BusinessEntityFieldType.VarChar },
				{ name: 'fbc', type: BusinessEntityFieldType.VarChar },
				{ name: 'clientBudget', type: BusinessEntityFieldType.Decimal },
				{ name: 'clientBudgetCurrency', type: BusinessEntityFieldType.VarChar },
				{ name: 'revenue', type: BusinessEntityFieldType.Decimal },
				{ name: 'revenueCurrency', type: BusinessEntityFieldType.VarChar },
				{ name: 'sendCustomEmails', type: BusinessEntityFieldType.Boolean },
				{ name: 'travelDestination', type: BusinessEntityFieldType.VarChar },
				{ name: 'comments', type: BusinessEntityFieldType.Text },
				{ name: 'assignedToTravelAgent', type: BusinessEntityFieldType.ForeignKey, inputName: 'assignedToTravelAgentId', inputProperty: 'assignedToTravelAgent.id', relatedManager: TravelAgent.manager },
				{ name: 'lostBecasuse', type: BusinessEntityFieldType.VarChar },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'webflowLeads',
        });
    }

}
export class WebflowLeadGenerated extends BusinessEntity
{
	dateAdded!: string;
	email: string | null = null;
	status: WebflowLeadStatus = WebflowLeadStatus.NEW;
	evyssaStatus: WebflowLeadEvyssaStatus | null = null;
	inHouseStatus: InHouseStatus | null = null;
	inHouseStatusChangedDate: string | null = null;
	sourceType: WebflowLeadSourceType = WebflowLeadSourceType.MANUAL;
	name: string | null = null;
	phone: string | null = null;
	fieldsAndValues: any | null = null;
	sourceUrl: string | null = null;
	sourceName: string | null = null;
	formId: string | null = null;
	submitEventName: string = 'thank_you_lead';
	approveEventName: string = 'approved_lead';
	gaClientId: string | null = null;
	fbp: string | null = null;
	fbc: string | null = null;
	clientBudget: number | null = null;
	clientBudgetCurrency: string = 'USD';
	revenue: number | null = null;
	revenueCurrency: string = 'USD';
	sendCustomEmails: boolean = true;
	travelDestination: string | null = null;
	comments: string | null = null;
	assignedToTravelAgent: TravelAgent | null = null;
	lostBecasuse: string | null = null;

}

export default WebflowLeadGenerated;