import React, { useEffect, useRef, useState } from 'react';
import { FunctionComponent } from 'react';
import { GalleryImage } from './SlickSliderComponent';
import SlidingImageContainerComponent from './SlidingImageContainerComponent';

interface LightboxComponentProps
{
    show: boolean;
    images: GalleryImage[];
    selectedImageIndex: number
    onClose: () => void;
}

const LightboxComponent: FunctionComponent<LightboxComponentProps> = (props) =>
{
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(props.selectedImageIndex);

    /** Disable scrolling while lightbox is shown */
    document.querySelector('body')!.style.overflow = props.show ? 'hidden' : 'auto';

    /** Swipe detection */
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50

    const onTouchStart = (e: any) =>
    {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () =>
    {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        //if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')

        // swipe logic:
        if (isLeftSwipe) navigate(selectedImageIndex + 1);
        if (isRightSwipe) navigate(selectedImageIndex - 1);
    }

    /** Copy selectedImageIndex from props. */
    useEffect(() =>
    {
        setSelectedImageIndex(props.selectedImageIndex);
    }, [props.selectedImageIndex]);

    /**
    * Navigate to the selected index.
    * 
    * @param index The image index.
    */
    const navigate = (index: number) => 
    {
        if (index < 0 || index >= props.images.length) return;

        setSelectedImageIndex(index);
    };

    /** Navigation markup */
    let enableNavigateLeft = selectedImageIndex > 0;
    let enableNavigateRight = selectedImageIndex < props.images.length - 1;

    /** Render. */
    return (
        <div className="x-lightbox disable-selection" style={{ display: props.show ? 'flex' : 'none' }}>
            <div className="x-lightbox-close" onClick={() => props.onClose()}>
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=" />
            </div>

            <div className="x-lightbox-main">
                <div className={'x-lightbox-navigate ' + (enableNavigateLeft ? '' : 'x-lightbox-navigate-hidden')} onClick={() => navigate(selectedImageIndex - 1)}>
                    <img className="x-lightbox-navigate-icon" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0yMCAwIDI0IDQwIiB3aWR0aD0iMjQiIGhlaWdodD0iNDAiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHBhdGggZD0ibTAgMGg1djIzaDIzdjVoLTI4eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDN2MjNoMjN2M2gtMjZ6IiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg==" />
                </div>
                <div className="x-lightbox-selected-image" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} >
                    <img src={props.images[selectedImageIndex].url} />
                </div>
                <div className={'x-lightbox-navigate ' + (enableNavigateRight ? '' : 'x-lightbox-navigate-hidden')} onClick={() => navigate(selectedImageIndex + 1)}>
                    <img className="x-lightbox-navigate-icon" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMjQgNDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMC0waDI4djI4aC01di0yM2gtMjN6IiBvcGFjaXR5PSIuNCIvPjxwYXRoIGQ9Im0xIDFoMjZ2MjZoLTN2LTIzaC0yM3oiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+" />
                </div>
            </div>

            <SlidingImageContainerComponent 
                images={props.images}
                enableArrows={false}
                selectedIndex={selectedImageIndex}
                className="x-lightbox-thumbnails"
                imageClassName="x-lightbox-thumbnail"
                onImageClick={(image) => navigate(image.index)}
            />
        </div>
    );
}

export default LightboxComponent;