import config from '@config';
import xObject from './xObject';

declare let window: any;

export enum TrackerEcommerceEventType
{
    ViewProducts,
    AddToWishList,
    AddToCart,
    RemoveFromCart,
    ViewCart,
    InitiateCheckout,
    AddPaymentInfo,
    Purchase,
    PaymentFailed,
    ChangePaymentMethod,
    Refund,
    ReserveProduct
}

/** Tracking info for a product */
export interface TrackerProduct
{
    id: number | string;
    name: string;
    price?: number;
    quantity?: number;
    category?: string;
    brand?: string;
    variant?: string,
    discount?: number;
    coupon?: number;
}

/** Tracking data for an eCommerce event. */
export interface TrackerEcommerceData
{
    type: TrackerEcommerceEventType,
    value: number;
    currency: string;
    products: TrackerProduct[];
    couponCode?: string;
    transactionId?: number | string;
    shippingCost?: number;
    tax?: number;
    paymentType?: string;
}

/** Extra user info for  enhanced Google Ads and/or Facebook Conversions API. */
export interface TrackerExtraUserData 
{
    emails?: string[],
    phoneNumbers?: string[],
    firstName?: string,
    lastName?: string,
    postalCode?: string, 
    streetAddress?: string,
    countryCode?: string,
    /** Google Analytics client ID (from the _ga cookie). */
    gaClientId?: string,
    /** Facebook pixel fbp cookie. */
    fbp?: string,
    /** Facebook pixel fbc cookie. */
    fbc?: string,
}

/**
 * Track user interactions with the app.
 * You should always inherit from this class and override the createInstance() and init() methods.
 */
export class Tracker extends xObject
{
    /** The singleton instance. */
    protected static _instance?: Tracker;
    /** Does the Tracker load the tracking scripts or are they already loaded with the classic <script> tags? */
    handleScriptLoading: boolean = true;
    /** Have the scripts started loading? */
    loadingScriptsStarted = false;
    /** Are the scripts loaded? */
    scripstAreLoaded = false;
    /** Is tracking enabled? */
    enabled = true;
    /** Track with Google Analytics/ Google Ads? */
    trackWithGoogle = false;
    /** Track with Facebook? */
    trackWithFacebook = false;
    /** Track with the Google Analytics Measurement Protocol? */
    trackWithGoogleMeasurementProtocol = false;
    /** Track with the Facebook Conversions API? */
    trackWithFacebookConversionsApi = false;
    /** Track the page view when the scripts load? */
    trackPageViewAtScriptLoad = true;
    /** Lazy load the tracking scripts? Eg: on use interaction or after a certain time passes after the page load. */
    lazyLoadScripts = false;
    /** The Google gtag object. */
    gtag?: Gtag.Gtag;
    /** The Facebook fbq object. */
    fbq?: facebook.Pixel.Event;

    //#region Singleton
    protected constructor()
    {
        super();
        
        this.init();
    }

    static get instance(): Tracker
    {
        if (this._instance === undefined) this._instance = this.createInstance();

        return this._instance;
    }

    /**
     * Create the singleton instance. Override this method to create a custom instance.
     */
    protected static createInstance(): Tracker
    {
        return new Tracker();
    }
    //#endregion

    /**
     * Load the tracking scripts.
     * Override this method to initialize the child class.
     */
    protected async init()
    {
        if (this.handleScriptLoading)
        {// scripts are loaded by the Tracker
            if (this.lazyLoadScripts)
            {// Load the tracking scripts when a user interects with the page or a certain amount of time expires after the page load
                addEventListener('scroll', () => this.loadScripts());
                addEventListener('mousemove', () => this.loadScripts());
                addEventListener('touchstart', () => this.loadScripts());
                addEventListener('touchmove', () => this.loadScripts());
                addEventListener('click', () => this.loadScripts());

                let timeout = 5000;
                setTimeout(() => this.loadScripts(), timeout);
            }
            else
            {// Load the tracking scripts immediately
                this.loadScripts();
            }
        }
        else
        {// scripts are already loaded with the classic <script> tags
            addEventListener('load', () => {
                this.gtag = window.gtag;
                this.fbq = window.fbq;
                this.fire('loaded');
                this.scripstAreLoaded = true;
            });
        }
    }

    /**
     * Load the tracking scripts. Fire the 'loaded' event when done.
     */
    private async loadScripts()
    {
        if (this.loadingScriptsStarted) return;
        this.loadingScriptsStarted = true;

        // console.log('Tracker: loading tracking scripts...');

        await Promise.all([this.loadFacebookScript(), this.loadGoogleScript()]);
        this.fire('loaded');
        this.scripstAreLoaded = true;

        // console.log('Tracker: tracking scripts loaded.');
    }

    /**
    * Load the Google Analytics tracking code.
    * https://developers.google.com/analytics/devguides/collection/gtagjs
    * https://developers.google.com/tag-platform/tag-manager/web/datalayer
    * https://support.google.com/google-ads/answer/7548399
    * https://support.google.com/google-ads/answer/12785474?visit_id=638155969923163305-1440919079&rd=1#
    */
    private loadGoogleScript()
    {
        return new Promise<void>((resolve) =>
        {
            // console.log('Tracker: loading Google Analytics...');

            if (!this.trackWithGoogle)
            {
                // console.log('Tracker: Google Analytics disabled.');
                resolve();
                return;
            }

            // Load the tracking script
            let script = document.createElement('script');
            script.src = 'https://www.googletagmanager.com/gtag/js?id=' + config.googleAnalyticsId;
            script.async = true;
            script.onload = () => {
                // Initialize the data layer (events in the data layer will be sent to Google as soon as the gtag script has been loaded)
                window.dataLayer = window.dataLayer || [];
                this.gtag = function () { window.dataLayer.push(arguments); }
                window.gtag = this.gtag;
                this.gtag('js', new Date());
                this.gtag('config', config.googleAnalyticsId!, { 'send_page_view': this.trackPageViewAtScriptLoad });

                // Add legacy Google Universal Analytics tag
                if (config.googleUniversalAnalyticsId != null)
                {
                    this.gtag('config', config.googleUniversalAnalyticsId, { 'send_page_view': this.trackPageViewAtScriptLoad });
                }

                // Add Google Ads tag
                if (config.googleAdsId != null)
                {
                    this.gtag('config', config.googleAdsId, { 'send_page_view': this.trackPageViewAtScriptLoad, 'allow_enhanced_conversions': true });
                }

                // Consent
                this.gtag('consent', 'default', {
                    'ad_storage': 'granted', 
                    // @ts-ignore
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted',
                    'analytics_storage': 'granted',
                    'functionality_storage': 'granted',
                    'personalization_storage': 'granted',
                });

                resolve();
                // console.log('Tracker: Google Analytics loaded.');
            };
            document.body.appendChild(script);
        });
    }

    /**
    * Load the Facebook pixel code.
    * https://www.facebook.com/business/help/952192354843755?id=1205376682832142
    */
    private loadFacebookScript()
    {
        // console.log('Tracker: loading Facebook pixel...');

        return new Promise<void>((resolve) =>
        {
            if (!this.trackWithFacebook)
            {
                // console.log('Tracker: Facebook pixel disabled.');
                resolve();
                return;
            }
            
            // A heavily modified version of the Facebook pixel code
            const f = function (p_window?: any, p_document?: any, p_script?: any, p_src?: any, n?: any, script?: any, onLoaded?: any)
            {
                if (p_window.fbq) return;
                n = p_window.fbq = function ()
                {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!p_window._fbq) p_window._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];

                script = p_document.createElement(p_script);
                script.async = true;
                script.src = p_src;
                script.onload = () => {
                    if (onLoaded) onLoaded();
                };
                document.body.appendChild(script);
            };

            f(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js', undefined, undefined, () => {
                //fbq('set', 'autoConfig', 'false', config.facebookPixelID); 
                fbq('init', config.facebookPixelId!);
                if (this.trackPageViewAtScriptLoad) fbq('track', 'PageView');
                this.fbq = fbq;

                resolve();
                // console.log('Tracker: Facebook pixel loaded.');
            });
        });
    }

    /**
    * Log a custom event.
    * https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
    * https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking
    * 
    * @param name 
    * @param parameters 
    * @param extraUserData
    */
    async logEvent(name: string, parameters: any = {}, extraUserData?: TrackerExtraUserData)
    {
        // Google
        this.logGoogleEvent(name, parameters);

        // Facebook
        this.logFacebookEvent(name, parameters);

        // Facebook Conversions API
        await this.logFacebookConversionsApiEvent(name, window?.location?.href, parameters, extraUserData);
    }

    /**
    * Log a page view (Google only).
    * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
    * https://developers.facebook.com/docs/meta-pixel/reference#standard-events
    */
    logPageView(pageTitle: string = document.title, pageUrl: string = location.href, extraUserData?: TrackerExtraUserData)
    {
        // Google
        let parameters: any = {
            page_title: pageTitle,
            page_location: pageUrl,
        };
        this.logGoogleEvent('page_view', parameters);

        // Facebook
        parameters = {
            content_name: pageTitle,
        };
        this.logFacebookEvent('ViewContent', parameters);

        // Facebook Conversions API
        this.logFacebookConversionsApiEvent('ViewContent', pageUrl, parameters, extraUserData);
    }

    /**
     * Log an eCommerce related event.
     * 
     * @param data eCommerce data
     * @param extraUserData
     */
    logEcommerceEvent(data: TrackerEcommerceData, extraUserData?: TrackerExtraUserData)
    {
        // Google data (GA4)
        // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce
        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
        let googleData = {
            transaction_id: data.transactionId,
            value: data.value,
            currency: data.currency,
            tax: data.tax,
            shipping: data.shippingCost,
            coupon: data.couponCode,
            payment_type: data.paymentType,
            items: data.products.map(product => ({
                item_id: product.id,
                item_name: product.name,
                price: product.price,
                quantity: product.quantity,
                item_category: product.category,
                item_brand: product.brand,
                item_variant: product.variant,
                google_business_vertical: 'retail'
            }))
        };

        // Google data (Universal Analytics)
        // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce
        let googleDataUniversal = {
            transaction_id: '' + data.transactionId + '',
            affiliation: 'Online Store',
            value: data.value,
            currency: data.currency,
            tax: data.tax,
            shipping: data.shippingCost,
            items: data.products.map(product => ({
                id: '' + product.id + '',
                name: product.name,
                brand: product.brand,
                category: product.category,
                variant: product.variant,
                price: product.price,
                quantity: product.quantity,
            }))
        };

        // Facebook data
        // https://developers.facebook.com/docs/meta-pixel/reference#standard-events
        let facebookData = {
            currency: data.currency,
            value: data.value,
            content_type: 'product',
            contents: data.products.map(product => ({
                id: product.id,
                title: product.name,
                item_price: product.price,
                quantity: product.quantity,
            }))
        };

        // Event name
        let googleEventName: string = '';
        let googleUniversalAnalyticsEventName: string = '';
        let facebookEventName: string = '';
        switch (data.type)
        {
            case TrackerEcommerceEventType.ViewProducts:
                {
                    googleEventName = 'view_item';
                    googleUniversalAnalyticsEventName = 'view_item';
                    facebookEventName = 'ViewContent';
                }
                break;
            case TrackerEcommerceEventType.AddToWishList:
                {
                    googleEventName = 'add_to_wishlist';
                    googleUniversalAnalyticsEventName = 'add_to_wishlist';
                    facebookEventName = 'AddToWishlist';
                }
                break;
            case TrackerEcommerceEventType.AddToCart:
                {
                    googleEventName = 'add_to_cart';
                    googleUniversalAnalyticsEventName = 'add_to_cart';
                    facebookEventName = 'AddToCart';
                }
                break;
            case TrackerEcommerceEventType.RemoveFromCart:
                {
                    googleEventName = 'remove_from_cart';
                    googleUniversalAnalyticsEventName = 'remove_from_cart';
                    facebookEventName = 'RemoveFromCart';
                }
                break;
            case TrackerEcommerceEventType.ViewCart:
                {
                    googleEventName = 'view_cart';
                    googleUniversalAnalyticsEventName = 'view_cart';
                    facebookEventName = 'ViewCart';
                }
                break;
            case TrackerEcommerceEventType.InitiateCheckout:
                {
                    googleEventName = 'begin_checkout';
                    googleUniversalAnalyticsEventName = 'begin_checkout';
                    facebookEventName = 'InitiateCheckout';
                }
                break;
            case TrackerEcommerceEventType.AddPaymentInfo:
                {
                    googleEventName = 'add_payment_info';
                    googleUniversalAnalyticsEventName = 'add_payment_info';
                    facebookEventName = 'AddPaymentInfo';
                }
                break;
            case TrackerEcommerceEventType.Purchase:
                {
                    googleEventName = 'purchase';
                    googleUniversalAnalyticsEventName = 'purchase';
                    facebookEventName = 'Purchase';
                }
                break;
            case TrackerEcommerceEventType.PaymentFailed:
                {
                    googleEventName = 'payment_failed';
                    googleUniversalAnalyticsEventName = 'payment_failed';
                    facebookEventName = 'PaymentFailed';
                }
                break;
            case TrackerEcommerceEventType.ChangePaymentMethod:
                {
                    googleEventName = 'change_payment_method';
                    googleUniversalAnalyticsEventName = 'change_payment_method';
                    facebookEventName = 'ChangePaymentMethod';
                }
                break;
            case TrackerEcommerceEventType.Refund:
                {
                    googleEventName = 'refund';
                    googleUniversalAnalyticsEventName = 'refund';
                    facebookEventName = 'Refund';
                }
                break;
        }

        // Log GA4 event
        this.logGoogleEvent(googleEventName, googleData, config.googleAnalyticsId);
        
        // Log Universal Analytics event
        if (config.googleUniversalAnalyticsId != null)
        {
            this.logGoogleEvent(googleUniversalAnalyticsEventName, googleDataUniversal, config.googleUniversalAnalyticsId);
        }

        // Log Google Ads event
        if (config.googleAdsId != null)
        {
            this.logGoogleEvent(googleEventName, googleData, config.googleAdsId);
        }

        // Log Facebook event
        this.logFacebookEvent(facebookEventName, facebookData);
        this.logFacebookConversionsApiEvent(facebookEventName, window?.location?.href, facebookData, extraUserData);
    }

    /**
    * Log a Google event with gtag.js.
    * https://developers.google.com/analytics/devguides/collection/gtagjs/events
    * https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
    * 
    * @param name Event name. 
    * @param parameters Event parameters.
    * @param sendTo The Google Analytics ID to send the event to. If not set, the event will be sent to the default Google Analytics ID.
    */
    logGoogleEvent(name: string, parameters: any = {}, sendTo?: string)
    {
        if (!this.enabled || !this.trackWithGoogle || this.gtag === undefined) return;
        let sendToLogText= sendTo !== undefined ? ` (sendTo: '${sendTo}'). ` : '';
        // console.log(`Tracker: log Google event '${name}'. ${sendToLogText}Parameters:`);
        // console.log(parameters);

        if (parameters == null) parameters = {};
        parameters.version = config.version;
        if (sendTo !== undefined) parameters.send_to = sendTo;

        this.gtag('event', name, parameters);
    }

    /**
     * Log a Google Ads conversion event.
     * https://support.google.com/google-ads/answer/7548399 (Event snippet)
     * https://support.google.com/google-ads/answer/12785474?visit_id=638155969923163305-1440919079&rd=1# (Enhanced conversions)
     * https://www.semetis.com/en/resources/articles/how-to-debug-google-enhanced-conversions-implementation
     * https://support.google.com/google-ads/answer/11956168#zippy=%2Csetup-has-incorrect-data-formatting
     * 
     * @param conversionLabel The conversion label
     * @param parameters The conversion parameters.
     */
    logGoogleAdsEvent(conversionLabel: string, parameters: any = {}, extraUserData?: TrackerExtraUserData)
    {
        if (!this.enabled || !this.trackWithGoogle || this.gtag === undefined) return;

        // console.log('Tracker: log Google Ads event ' + conversionLabel + '. Parameters:');
        // console.log(parameters);
        // console.log('Extra user data:');
        // console.log(extraUserData);

        if (parameters == null) parameters = {};
        parameters.version = config.version;

        // Google Ads enhanced conversion user data
        // https://support.google.com/google-ads/answer/13258081?hl=en#zippy=%2Cidentify-and-define-your-enhanced-conversions-fields
        if (extraUserData !== undefined && this.gtag !== undefined)
        {
            let user_data = {
                email: extraUserData.emails !== undefined && extraUserData.emails.length > 0 ? extraUserData.emails[0] : undefined,
                phone_number: extraUserData.phoneNumbers !== undefined && extraUserData.phoneNumbers.length > 0 ? extraUserData.phoneNumbers[0] : undefined,
                address: {
                    first_name: extraUserData.firstName,
                    last_name: extraUserData.lastName,
                    postal_code: extraUserData.postalCode,
                    street: extraUserData.streetAddress,
                    country: extraUserData.countryCode
                }
            };

            // console.log('user_data:');
            // console.log(user_data);
            
            this.gtag('set', 'user_data', user_data);
        }

        // Log the event
        this.logGoogleEvent('conversion', {
            send_to: config.googleAdsId + '/' + conversionLabel,
            ...parameters
        });
    }

    /**
    * Log a Facebook pixel event via JS
    * https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/
    * 
    * @param name Event name. 
    * @param parameters Event parameters.
    */
    logFacebookEvent(name: string, parameters: any = {})
    {
        if (!this.enabled || !this.trackWithFacebook || this.fbq === undefined) return;

        // console.log('Tracker: log Facebook event ' + name + '');

        if (parameters == null) parameters = {};
        parameters.version = config.version;

        this.fbq('trackCustom', name, parameters);
    }

    /**
     * Log an event with the Facebook Conversion API.
     * 
     * @param name Event name.
     * @param sourceUrl The site URL where the event happened.
     * @param parameters Event parameters.
     */
    async logFacebookConversionsApiEvent(name: string, sourceUrl: string, parameters: any = {}, extraUserData?: TrackerExtraUserData)
    {
        if (!this.enabled || !this.trackWithFacebookConversionsApi) return;

        // console.log('Tracker: log Facebook Conversions API event ' + name + '');

        // dynamic import to avoid loading the whole apollo client in the bundle
        const { client } = await import('@xFrame4/business/GraphQlClient');
        const { gql } = await import('@apollo/client');

        let mutation = `
            mutation TrackWithFacebookConversionsApi($input: FacebookConversionApiInput!) {
                trackWithFacebookConversionsApi(input: $input)
            }
        `;

        let input = {
            eventName: name,
            eventSourceUrl: sourceUrl,
            parameters: JSON.stringify(parameters),
            userParameters: JSON.stringify({
                emails: extraUserData?.emails ?? [],
                phones: extraUserData?.phoneNumbers ?? [],
                firstName: extraUserData?.firstName,
                lastName: extraUserData?.lastName,
                //_fbp: extraUserData?.fbp, // for a strange reason I've had to add the underscore otherwise Apollo gives 'Invalid parameter' error
                //_fbc: extraUserData?.fbc,
            })
        };

        let { data } = await client.mutate({
            mutation: gql(mutation),
            variables: {
                input: input
            }
        });

        return data.trackWithFacebookConversionsApi as boolean;
    }

    /**
     * Log an event with the Google Analytics Measurement Protocol.
     * https://developers.google.com/analytics/devguides/collection/protocol/ga4
     * 
     * @param name Event name.
     * @param parameters Event parameters.
     * @param extraUserData Extra user data.
     */
    async logGooglMeasurementProtocolEvent(name: string, parameters: any = {}, extraUserData?: TrackerExtraUserData)
    {
        if (!this.enabled || !this.trackWithGoogleMeasurementProtocol) return;

        // console.log('Tracker: log Google Analytics Measurement Protocol event ' + name + '');

        // dynamic import to avoid loading the whole apollo client in the bundle
        const { client } = await import('@xFrame4/business/GraphQlClient');
        const { gql } = await import('@apollo/client');

        let mutation = `
            mutation TrackWithGoogleMeasurementProtocol($input: GoogleMeasurementProtocolInput!) {
                trackWithGoogleMeasurementProtocol(input: $input)
            }
        `;

        let input = {
            measurementId: config.googleAnalyticsId,
            clientId: extraUserData?.gaClientId,
            eventName: name,
            parameters: JSON.stringify(parameters),
        };

        let { data } = await client.mutate({
            mutation: gql(mutation),
            variables: {
                input: input
            }
        });

        return data.trackWithGoogleMeasurementProtocol as boolean;
    }
}

export default Tracker;