import WebflowLead from '@business/forms/WebflowLead';
import config from '@config';
import { getCookie } from '@xFrame4/common/Functions';
import { WebflowLeadSourceType } from '@business/forms/generated/WebflowLead.generated';
import { TrackerExtraUserData } from '@xFrame4/common/Tracker';
import WebflowTracker from '@business/tracking/WebflowTracker';

export type FormDataObject = {
    /** The submitted fields and values. */
    fieldsAndValues: EvyssaFormField[],
    /** The form ID. */
    formId: string,
    /** The form source URL */
    sourceUrl: string,
    /** The form source name */
    sourceName?: string,
    /** What to do after the form is submitted. */
    submitBehavior: FormSubmitBehavior
    /** Thank you page URL after the form is submitted. */
    thankYouPageUrl?: string,
    /** Redirect URL after the form is submitted. */
    redirectUrl?: string,
    /** The event to be tracked when the form is submitted. */
    submitEventName?: string,
    /** The name of the event to be tracked when the lead is approved. */
    approveEventName?: string,
    /** Special field: email */
    email: string,
    /** Special field: name */
    name?: string,
    /** Special field: phone */
    phone?: string,
    /** The Google Analytics Client ID from the _ga cookie. */
    googleAnalyticsClientId: string
    /** This cookie is used by Facebook to store and track visits across websites. */
    facebookPixelFbp: string,
    /** This cookie is set when a user clicks on a Facebook ad to arrive at your website. It helps Facebook link the click on the ad to the subsequent activity on your website. */
    facebookPixelFbc: string,
    /** Enable custom email sending ? */
    sendCustomEmails?: boolean,
}

export enum FormSubmitBehavior
{
    RedirectToThankYouPage = 'redirect_to_thank_you',
    RedirectToUrlAndOpenThankYouPageInNewTab = 'redirect_to_url_thank_you_in_new_tab',
}

export type EvyssaFormField = {
    /** The field name. */
    name: string,
    /** The field value. */
    value: any,
    /** The field type. */
    type: string,
}

export class FormHelper
{
    private static savedSubmitHandlers: any[] = [];
    private static isScriptTriggerClick: boolean = false; // flag to prevent infinite loop

    setup()
    {
        window.addEventListener('load', () => {
            // remove all submit event handlers from the document
            //console.log('Removing all submit event handlers from the document.');
            //FormHelper.removeSubmitHandlers();

            // find all forms with attribute [dope-form=true]
            let forms = document.querySelectorAll('form[dope-form=true]') as NodeListOf<HTMLFormElement>;
            forms.forEach((form: HTMLFormElement) =>
            {
                this.setupForm(form);
            });
        });
    }

    setupForm(form: HTMLFormElement)
    {
        let submitButton = form.querySelector('input[type=submit]') as HTMLInputElement;

        // on submit
        submitButton.addEventListener('click', async (event) =>
        {
            console.log('Submit button click.');

            if (FormHelper.isScriptTriggerClick)
            {
                FormHelper.isScriptTriggerClick = false; 
                return; // prevent infinite loop
            }
            submitButton.disabled = true;

            // log a tracking event
            WebflowTracker.instance.logEvent('form_submit_button_click', { form_id: form.id });

            try
            {
                event.preventDefault();

                // check if required fields are filled
                let isFormValid = this.validateForm(form);

                if (isFormValid)
                {
                    console.log('Form is valid.');

                    // disable the submit button and set the text from the 'data-wait' attribute
                    console.log('Disabling the submit button');
                    let submitButtonWaitText = submitButton.getAttribute('data-wait');
                    if (submitButtonWaitText != null)
                    {
                        submitButton.value = submitButtonWaitText;
                    }

                    // create an object with the fields and values and other necessary data
                    let formDataObject = this.createFormDataObject(form);

                    // send the object to the GraphQL endpoint (if sendCustomEmails is true then the redirect is done here)
                    console.log('Sending the form data to the GraphQL endpoint.');
                    await this.sendFormDataObject(formDataObject);
                    console.log('Form data sent to the GraphQL endpoint.');

                    // submit the form with Webflow (redirect is done by Webflow)
                    console.log('Triggering form submit event.');
                    let submitEvent = new Event('submit', { bubbles: true, cancelable: true });
                    if (!form.dispatchEvent(submitEvent)) 
                    {
                        console.log('Form submission prevented by an event handler');
                    }

                    // TODO: Remove the comments after the testing period. Custom redirects are disabled until then.
                    /*
                    if (!formDataObject.sendCustomEmails)
                    {
                        console.log('Triggering form submit event.');
                        let submitEvent = new Event('submit', { bubbles: true, cancelable: true });
                        if (!form.dispatchEvent(submitEvent)) 
                        {
                            console.log('Form submission prevented by an event handler');
                        }
                    }
                    */
                }
                else
                {
                    console.log('Form is not valid.');
                    // trigger the click event on the submit button to show the error messages
                    FormHelper.isScriptTriggerClick = true;
                    submitButton.disabled = false;
                    submitButton.click();
                }
            }
            catch (error)
            {
                console.error(error);
            }

            submitButton.disabled = false;
        });
    }

    /**
     * Check if all required fields are filled.
     * 
     * @param form
     */
    validateForm(form: HTMLFormElement): boolean
    {
        let isFormValid = true;

        // find all required fields
        let requiredFields = form.querySelectorAll('[required]') as NodeListOf<HTMLInputElement>;

        // check if all required fields are filled
        requiredFields.forEach((field: HTMLInputElement) =>
        {
            if (field.value == '')
            {
                isFormValid = false;
            }
        });

        return isFormValid;
    }

    /**
     * Remove all submit event handlers from the document using jQuery.
     */
    static removeSubmitHandlers(): void
    {
        // Get the document.
        const doc = $(document);

        // Get the submit event handlers.
        const submitHandlers = ($ as any)._data(document, 'events').submit;

        // Check if there are any submit handlers.
        if (submitHandlers)
        {
            // Make a copy of the handlers.
            FormHelper.savedSubmitHandlers = [...submitHandlers];

            // Remove the submit handlers.
            for (let i = 0; i < submitHandlers.length; i++)
            {
                doc.off('submit', submitHandlers[i].handler);
            }
        }
    }

    /**
     * Add back the submit event handlers to the document using jQuery.
     */
    static addBackSubmitHandlers(): void
    {
        // Get the document.
        const doc = $(document);

        // Add the submit handlers back.
        for (let i = 0; i < FormHelper.savedSubmitHandlers.length; i++)
        {
            doc.on('submit', FormHelper.savedSubmitHandlers[i].handler);
        }

        // Clear the savedHandlers array
        FormHelper.savedSubmitHandlers = [];
    }

    /**
     * Create a serializable object with the fields and values and other necessary data.
     * 
     * @param form
     */
    createFormDataObject(form: HTMLFormElement): FormDataObject
    {
        let formDataObject: FormDataObject = {} as FormDataObject;

        // get the fields and values
        let fieldsAndValues: EvyssaFormField[] = [];
        let fields = form.querySelectorAll('input, textarea, select') as NodeListOf<HTMLInputElement>;
        let skipFields = [
            'G recaptcha response',
            'g-recaptcha-response',
            'recaptcha',
            'captcha',
        ]

        fields.forEach((field: HTMLInputElement) =>
        {
            let shouldSkipThisField = skipFields.some((skipField) => field.name.toLowerCase().includes(skipField.toLowerCase()));
            
            if (field.name != '' && !shouldSkipThisField)
            {
                // process value
                let value: any = field.value;
                if (field.type == 'checkbox')
                {
                    value = field.checked;
                }

                let evyssaFormField: EvyssaFormField = {
                    name: field.name,
                    value: value,
                    type: field.type,
                };
                fieldsAndValues.push(evyssaFormField);
            }
        });
        formDataObject.fieldsAndValues = fieldsAndValues;

        // get the form ID
        formDataObject.formId = form.id;

        // get the source URL
        let sourceUrl = window.location.href;
        formDataObject.sourceUrl = sourceUrl;

        // get the source name (the page title - strip the '· Evyssa Vacations' from the end)
        if (document.title != '')
        {
            let sourceName = document.title;
            formDataObject.sourceName = sourceName.replace('· Evyssa Vacations', '').trim();
        }

        // get the submit behavior (attribute [submit-behavior]), if not set the default is 'redirect_to_thank_you'
        let submitBehavior = form.getAttribute('submit-behavior');
        if (submitBehavior == null) submitBehavior = FormSubmitBehavior.RedirectToThankYouPage;
        formDataObject.submitBehavior = submitBehavior as FormSubmitBehavior;

        // get the thank you page URL (attribute [data-redirect]) - This is the default redirect URL attribute [redirect-url] is the same as this one. This is set by Webflow.
        let thankYouPageUrl = form.getAttribute('data-redirect');
        if (thankYouPageUrl != null) formDataObject.thankYouPageUrl = thankYouPageUrl;

        // get the secondary redirect URL (attribute [secondary-redirect-url]) - A secondary redirect URL besides the default one. Eg: an affiliate link. This is set by us.
        let redirectUrl = form.getAttribute('secondary-redirect-url');
        if (redirectUrl != null) formDataObject.redirectUrl = redirectUrl;

        // get the submit event name (attribute [submit-event-name])
        let submitEventName = form.getAttribute('submit-event-name');
        if (submitEventName != null) formDataObject.submitEventName = submitEventName;

        // get the approve event name (attribute [approve-event-name])
        let approveEventName = form.getAttribute('approve-event-name');
        if (approveEventName != null) formDataObject.approveEventName = approveEventName;

        // get the email (input type is email)
        let emailField = form.querySelector('input[type=email]') as HTMLInputElement;
        if (emailField != null) formDataObject.email = emailField.value;

        // get the name (input name contains name or Name)
        let nameField = form.querySelector('input[name*=name], input[name*=Name]') as HTMLInputElement;
        if (nameField != null) formDataObject.name = nameField.value;

        // get the phone (input name contains phone or Phone)
        let phoneField = form.querySelector('input[name*=phone], input[name*=Phone]') as HTMLInputElement;
        if (phoneField != null) formDataObject.phone = phoneField.value;

        // read the Google Analytics Client ID from the _ga cookie
        let googleAnalyticsClientId = this.getGoogleAnalyticsClientId(config.googleAnalyticsId!);
        if (googleAnalyticsClientId != null) formDataObject.googleAnalyticsClientId = googleAnalyticsClientId;

        // read the Facebook Pixel cookies
        let facebookPixelCookies = this.getFacebookPixelFbpAndFbc();
        if (facebookPixelCookies.facebookPixelFbp != null) formDataObject.facebookPixelFbp = facebookPixelCookies.facebookPixelFbp;
        if (facebookPixelCookies.facebookPixelFbc != null) formDataObject.facebookPixelFbc = facebookPixelCookies.facebookPixelFbc;

        // get the 'send-custom-emails' attribute
        let sendCustomEmails = form.getAttribute('send-custom-emails');
        if (sendCustomEmails != null)
        {// value from attribute
            formDataObject.sendCustomEmails = sendCustomEmails == 'true';
        }
        else
        {// true by default
            formDataObject.sendCustomEmails = true;
        }

        // return the object
        return formDataObject;
    }

    /**
     * Send the FormDataObject to the GraphQL endpoint.
     * 
     * @param formDataObject
     */
    async sendFormDataObject(formDataObject: FormDataObject)
    {
        // Send the form data to the GraphQL endpoint
        let webflowLead = new WebflowLead();
        webflowLead.sourceType = WebflowLeadSourceType.WEBFLOW;
        webflowLead.formId = formDataObject.formId;
        if (formDataObject.submitEventName != null) webflowLead.submitEventName = formDataObject.submitEventName;
        if (formDataObject.approveEventName != null) webflowLead.approveEventName = formDataObject.approveEventName;
        webflowLead.sourceUrl = formDataObject.sourceUrl;
        webflowLead.sourceName = formDataObject.sourceName ?? null;
        webflowLead.email = formDataObject.email;
        webflowLead.name = formDataObject.name ?? null
        webflowLead.phone = formDataObject.phone ?? null;
        webflowLead.fieldsAndValues = formDataObject.fieldsAndValues;
        webflowLead.gaClientId = formDataObject.googleAnalyticsClientId ?? null;
        webflowLead.fbp = formDataObject.facebookPixelFbp ?? null;
        webflowLead.fbc = formDataObject.facebookPixelFbc ?? null;
        webflowLead.sendCustomEmails = formDataObject.sendCustomEmails ?? true;
        webflowLead.save(); // do not wait for the response (this may cause issues while testing if the timeout is too short) 

        // Track the submit event
        let extraUserData: TrackerExtraUserData = {
            emails: [formDataObject.email],
            phoneNumbers: [formDataObject.phone ?? ''],
            fbp: formDataObject.facebookPixelFbp ?? null,
            fbc: formDataObject.facebookPixelFbc ?? null,
        };

        let parameters: any = {};
        for (let i = 0; i < formDataObject.fieldsAndValues.length; i++)
        {
            let field = formDataObject.fieldsAndValues[i];
            parameters[field.name] = field.value;
        }
        await WebflowTracker.instance.logEvent(webflowLead.submitEventName, parameters, extraUserData);
        if (webflowLead.submitEventName != WebflowLead.genericSubmitEventName)
        {
            // The generic submit event is always logged
            await WebflowTracker.instance.logEvent(WebflowLead.genericSubmitEventName, parameters, extraUserData);
        }
        WebflowTracker.instance.logGoogleAdsEvent(config.googleAdsConversionIdFormSubmit, parameters, extraUserData);

        // wait a bit then redirect if needed
        await new Promise(r => setTimeout(r, 1000));

        // Redirect if custom email sendig is enabled. If not, the redirect is done by the Webflow form.
        // TODO: Remove the comments after the testing period. Custom redirects are disabled until then.
        /**
        if (formDataObject.sendCustomEmails)
        {
            if (formDataObject.submitBehavior == FormSubmitBehavior.RedirectToThankYouPage)
            {
                //console.log('Redirecting to the thank you page with FormHelper.');
                window.location.href = config.webflowBaseUrl + formDataObject.thankYouPageUrl!.replace('/','');
            }
            else if (formDataObject.submitBehavior == FormSubmitBehavior.RedirectToUrlAndOpenThankYouPageInNewTab && formDataObject.redirectUrl != null)
            {
                //console.log('Redirecting to the thank you page in a new tab with FormHelper.');
                //console.log('Opening the redirect URL in the same tab.');
                window.open(formDataObject.thankYouPageUrl);
                window.location.href = config.webflowBaseUrl + formDataObject.redirectUrl.replace('/','');
            }
        }
         */
    }

    /**
     * Get the Google Analytics Client ID from the _ga cookie.
     * 
     * @param measurementId The Google Analytics 4 Measurement ID (e.g. G-XXXXXXXXXX).
     */
    getGoogleAnalyticsClientId(measurementId: string) 
    {
        var cookieName = "_ga_" + measurementId.substring(2);  // convert measurementId to cookieName
        var ga4Cookie = document.cookie.split('; ').find(row => row.startsWith(cookieName));
        if (ga4Cookie) 
        {
            return ga4Cookie.split('=')[1].split('.').slice(2, 3)[0];
        }
        else 
        {
            return null;
        }
    }

    /**
     * Get the Facebook Pixel cookies
     */
    getFacebookPixelFbpAndFbc()
    {
        let facebookPixelFbp = getCookie('_fbp');
        let facebookPixelFbc = getCookie('_fbc');

        return { facebookPixelFbp, facebookPixelFbc };
    }
}

export default FormHelper;